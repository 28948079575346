<template>
  <div>
    <nav-bar headerTitle="训练记录" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container">
      <van-list v-model="loading" :finished="finished" @load="getList">
        <van-index-bar :sticky-offset-top="stickyHeight" :index-list="['', '']">
          <template v-for="(value, name, oIndex) in totalList">
            <van-index-anchor>{{ name }}</van-index-anchor>
            <div class="list-box">
              <record-item :isRecent="index === 0 && oIndex === 0" :record="item" v-for="item, index in value"></record-item>
            </div>
          </template>
        </van-index-bar>
      </van-list>
      <img v-if="records.length === 0" class="empty" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/empty.png" alt="">
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import recordItem from './components/record-item'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    recordItem
  },
  data() {
    return {
      stickyHeight: 0,
      totalList: {}, // 训记对象  时间：数组
      records: [], // 训记列表
      pageNum: 1,

      loading: false,
      finished: false,
    }
  },
  computed: {},
  async created() {
    // 初始化
    await this.$getAllInfo();
    initBack()
    // 获取链接参数
    let linkParams = getParam()

  },
  mounted() {
    this.stickyHeight = this.$refs.head.$el.offsetHeight
  },


  methods: {
    newAppBack,

    getList() {
      this.$axios.post(this.baseURLApp + '/store/userTrain/planList', {
        pageNum: this.pageNum,
        pageSize: 20,
        userId: this.userId
      }).then((res) => {
        this.records = res.data.records
        if (this.records.length < 20) {
          this.finished = true
        }
        this.pageNum++
        this.records.map((item, index) => {
          if (!this.totalList[item.yearMouth]) {
            this.$set(this.totalList, item.yearMouth, [])
          }
          this.totalList[item.yearMouth].push(item)
        })
        this.loading = false
      })
    },
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  * {
    box-sizing: border-box;
  }
  width: 100%;
  padding: calc(100px + env(safe-area-inset-top)) 0 env(safe-area-inset-bottom);
  .list-box {
    background-color: #fff;
    padding: 0 32px;
  }
  .empty {
    width: 208px;
    display: block;
    margin: 200px auto;
  }
  /deep/.van-index-anchor {
    line-height: 80px;
    font-size: 24px;
    color: #6c727a;
    background-color: #f9fafb;
    font-weight: normal;
  }
  /deep/.van-index-anchor--sticky {
    background-color: #f9fafb;
  }
}
</style>
