<template>
  <div>
    <nav-bar headerTitle="私教训练报告" ref="head" backColor="#ffffff" :show-back="true" :header-back="newAppBack" />
    <div class="container" :style="{'padding-top': appType() === 'and' ? '95px' : 'auto'}">
      <div class="sb-title f36" :style="{'margin-top': appType() === 'and' ? '32px' : '0'}">私教训练报告</div>
      <div class="top">
        <p @click="goStatistics" class="f32 fw5" style="color: #fff;">
          {{curMonth}}月训练统计
          <van-icon name="arrow" />
        </p>
        <p v-if="!recordList.length" class="f22" style="color: #F5F5F5; margin: 8px 0 0;">本月还未进行任何训练，快去约课吧~</p>
        <muscle :list="muscleList" :sex="sex"></muscle>
      </div>
      <!-- 列表 -->
      <div class="bottom">
        <div class="row-between-center" @click="goRecordList">
          <p class="f32 fw6">训练记录</p>
          <van-icon name="arrow" />
        </div>
        <div class="list-box">
          <record-item :isRecent="index === 0" :record="item" v-for="item, index in recordList"></record-item>
        </div>
        <img v-if="recordList.length === 0" class="empty" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/empty.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import recordItem from './components/record-item'
import muscle from './components/muscle'
import { newAppBack, initBack, appType } from '@/lib/appMethod'
import { getFormatDate } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    recordItem,
    muscle
  },
  data() {
    return {
      muscleList: [],
      sex: 1,
      recordList: [],

      curMonth: 0,
    }
  },
  computed: {},
  async created() {
    // 初始化
    await this.$getAllInfo();
    initBack()

    this.getList()
    this.getData()
  },


  methods: {
    appType,
    newAppBack,
    goStatistics() {
      this.$router.push('/training-records/statistics')
    },
    goRecordList() {
      this.$router.push('/training-records/list')
    },
    getData() {
      const now = new Date();
      const year = now.getFullYear()
      const month = now.getMonth()
      this.curMonth = month + 1
      const startDate = getFormatDate(new Date(year, month, 1))
      const endDate = getFormatDate(new Date(year, month+1, 1))

      this.$axios.post(this.baseURLApp + '/store/userTrain/userMuscle', {
        startTime: startDate,
        endTime: endDate,
        userId: this.userId
      }).then((res) => {
        this.muscleList = res.data.userMuscleList
        this.sex = res.data.sex
      })
    },
    getList() {
      this.$axios.post(this.baseURLApp + '/store/userTrain/planList', {
        pageNum: 1,
        pageSize: 5,
        userId: this.userId
      }).then((res) => {
        this.recordList = res.data.records
      })
    },
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  * {
    box-sizing: border-box;
  }
  width: 100%;
  padding: calc(130px + env(safe-area-inset-top)) 0 40px;
  padding: calc(130px + constant(safe-area-inset-top)) 0 40px;
  background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/bg1.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #F9FAFB;
  position: relative;
  .sb-title {
    width: 100%;
    line-height: 108px;
    position: absolute;
    top: 0;
    top: env(safe-area-inset-top);
    left: 0;
    text-align: center;
    color: #fff;
  }
  .top {
    padding: 0 32px;
    margin: 0 0 32px;
    
  }
  .bottom {
    color: #3c454e;
    background-color: #fff;
    padding: 32px;
    .empty {
    width: 208px;
    display: block;
    margin: 200px auto;
  }
  }
}
</style>
