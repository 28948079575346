<template>
  <div>
    <nav-bar v-if="fromOrigin !== 'ipad'" ref="head" backColor="#ffffff" :show-back="true" :show-share="true"
      :header-back="newAppBack" @right-txt-click="handleShare" />
    <div class="container">
      <div class="share-box" ref="abc">
        <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/mini-share.jpg"
          alt />
        <div class="info">
          <p class="p1 f22 fw5">
            消耗
            <span class="BebasNeueBold">{{ recordData.kcal || 0 }}</span>kcal，训练
            <span class="BebasNeueBold">{{ recordData.motionNum || 0 }}</span>个动作
          </p>
          <p class="p2 f22">我刚刚在超鹿完成了一次私教训练！</p>
        </div>
      </div>
      <img v-if="image" class="base64" :src="image" alt />

      <div class="course-box" :style="{ 'padding-top': appType() === 'and' ? '95px' : 'auto' }">
        <h2 class="f36 fw6">{{ recordData.courseName }}</h2>
        <p class="desc f22">{{ recordData.courseIntro }}</p>
        <div class="info row-start-center">
          <p class="p1 f24 fw5 row-start-center">
            <van-icon name="clock-o" />
            {{ recordData.courseTime }}
          </p>
          <p class="p2 f24 fw5 row-start-center">
            <van-icon name="location-o" />
            {{ recordData.venueName }}
          </p>
        </div>
      </div>
      <div class="content-box">
        <!-- 教练 -->
        <div class="row-start-center teacher">
          <img :src="recordData.teacherInfo.teacherImage" alt class="avator" />
          <div>
            <p class="p1 f28 fw6">{{ recordData.teacherInfo.teacherName }}</p>
            <p class="p2 f24">这是你第{{ recordData.userTeacherTrainNum }}次跟Ta一起训练</p>
          </div>

          <!-- 你真棒啊 -->
          <img class="tip"
            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/youaregood.png"
            alt />
        </div>
        <div style="background-color: rgba(249, 250, 251, 1);">
          <van-tabs color="rgba(60, 69, 78, 1)" v-model="scrollActive" scrollspy sticky :offset-top="stickyHeight">
            <van-tab class="col-start-center" title="训练统计">
              <div class="radius-box">
                <p class="p1 f24">本次训练量</p>
                <!-- 有体重 -->
                <div class="row-start-center" v-if="recordData.weightFlag == 1">
                  <p class="f72 fw6 BebasNeueBold">{{ recordData.trainingVolume }}</p>
                  <div v-if="recordData.record == 1" class="tag gradient f20 fw6">破记录</div>
                </div>
                <!-- 无体重 -->
                <div class="row-start-center no-weight" v-else>
                  <p class="f28 fw5">当前暂无体重数据，无法计算训练量</p>
                  <div class="tag black f24 fw5" @click="weightShow = true">手动填写</div>
                </div>
                <p class="p2 f22">共消耗{{ recordData.kcal }}，训练{{ recordData.motionNum }}个动作，新学{{ recordData.newMotionNum
                }}个动作</p>
                <p class="p3 f22" @click="whatIsTrainShow = true">什么是训练量？</p>
              </div>
            </van-tab>

            <van-tab title="训练内容">
              <h3 class="scroll-title f32 fw6">训练内容</h3>
              <div class="train-box" v-for="motion in recordData.motionList">
                <p class="t-title f28 fw6">{{ motion.mouldName }}</p>
                <div class="t-item" v-for="item in motion.list">
                  <div class="flex">
                    <div @click="imagePreview(item.motionImage)" class="t-photo flex-none"
                      :style="`background-image: url(${item.motionImage})`"></div>
                    <div class="t-info flex-auto">
                      <div class="name row-start-center">
                        <p class="f24 fw6">{{ item.motionName }}</p>
                        <div v-if="item.label == 1" class="tag purple f20">新动作</div>
                        <div v-if="item.label == 2" class="tag gradient f20">破记录</div>
                      </div>
                      <div class="tags flex flex-wrap f20">
                        <div class="tag grey" v-for="muscle in item.muscle.slice(0, 3)">{{ muscle }}</div>
                        <div v-show="item.muscle.length > 3" @click="lookMuscle(item)" class="tag"
                          style="color: #267DFF;">
                          共{{ item.muscle.length }}处
                          <van-icon name="arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <detail-data v-if="item.motionDetailList && item.motionDetailList.length > 0" :dataObj="item">
                  </detail-data>
                </div>
              </div>
            </van-tab>

            <van-tab title="训练总结" v-if="recordData.newTrainOpinion">
              <h3 class="scroll-title f32 fw6">训练总结</h3>
              <div class="train-box" style="padding-bottom: 16px;">
                <div class="progress-1 row-between-center">
                  <p class="f24 fw5">身体情况</p>
                  <div class="bar flex-auto">
                    <div class="target fw6 f28 row-center-center BebasNeueBold">{{
                        recordData.newTrainOpinion.physicalCondition
                    }}</div>
                    <div class="bar-grey" ref="bar">
                      <div class="bar-real"
                        :style="`width: ${barWidth / 10 * recordData.newTrainOpinion.physicalCondition}px`"></div>
                    </div>
                  </div>
                  <p class="f20">{{ bodyStatus(recordData.newTrainOpinion.physicalCondition) }}</p>
                </div>
                <div class="progress-1 row-between-center">
                  <p class="f24 fw5">训练强度</p>
                  <div class="bar flex-auto">
                    <div class="target fw6 f28 row-center-center BebasNeueBold">{{
                        recordData.newTrainOpinion.trainingIntensity
                    }}</div>
                    <div class="bar-grey">
                      <div class="bar-real"
                        :style="`width: ${barWidth / 10 * recordData.newTrainOpinion.trainingIntensity}px`"></div>
                    </div>
                  </div>
                  <p class="f20">{{ trainLevel(recordData.newTrainOpinion.trainingIntensity) }}</p>
                </div>

                <div class="summary">
                  <p class="f24 fw6">课程总结</p>
                  <p class="p2 f22">{{ recordData.newTrainOpinion.finalReport }}</p>
                </div>
              </div>
            </van-tab>

            <van-tab title="课后作业" v-if="recordData.newTrainOpinion && recordData.newTrainOpinion.homeWork">
              <h3 class="scroll-title f32 fw6">课后作业</h3>
              <div class="train-box" style="padding-bottom: 16px;">
                <div class="work-item f24 fw5">{{ recordData.newTrainOpinion.homeWork }}</div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <!-- 导航 -->
      </div>
    </div>

    <!--  分享海报  -->
    <detail-poster v-if="recordId && appTypeStr === 'mini'" :id="recordId"></detail-poster>
    <!-- 什么是训练量 -->
    <van-popup class="common-dialog" v-model="whatIsTrainShow">
      <div class="content col-start-center">
        <p class="title f36 fw6">什么是训练量？</p>
        <p class="desc f28">训练量是综合你本节课锻炼的组数、负重，通过一定的计算得出的。数字越大表示本节课得到的锻炼越多。</p>
        <div class="button row-center-center f32" @click="whatIsTrainShow = false">我知道了</div>
      </div>
    </van-popup>

    <!-- 输入体重 -->
    <van-popup class="common-dialog" v-model="weightShow">
      <div class="content col-start-center">
        <p class="title f36 fw6">您的体重</p>
        <p class="desc f28">由于计算训练量需要使用您的体重数据，请在下方填写并提交：</p>
        <div class="input-box row-center-center">
          <input class="flex-auto" v-model="userWeight" placeholder="请输入" type="text" />
          <span class="f28">kg</span>
        </div>
        <div class="button row-center-center f32" @click="submitWeight">提交</div>
      </div>
    </van-popup>

    <!-- 肌肉详情 -->
    <van-popup class="common-dialog" v-model="muscleShow">
      <div class="content col-start-center">
        <p class="title f36 fw6">{{ muscleShowObj.name }}</p>
        <p class="sub-title f28">该动作所训练的肌肉如下：</p>
        <div class="tags flex flex-wrap">
          <div class="tag grey f20" v-for="muscle in muscleShowObj.list">{{ muscle }}</div>
        </div>
        <div class="button row-center-center f32" @click="muscleShow = false">我知道了</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import wx from 'weixin-js-sdk'
import navBar from '@/components/nav-bar/nav-bar'
import detailPoster from './components/detail-poster'
import detailData from './components/detail-data'
import { newAppBack, initBack, appType } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import Regular from '@/lib/regular'
import html2canvas from 'html2canvas'
import { ImagePreview } from 'vant'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    detailPoster,
    detailData
  },
  data() {
    return {
      recordId: '',
      recordData: {
        teacherInfo: {},
        motionList: [],
        newTrainOpinion: {}
      }, // 训练数据

      shareShow: false, // 分享显示
      shareParams: {
        title: '快来看看我的训练成果！',
        miniImage: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring-awakening-2022/presets/miniImage22.jpg',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },

      scrollActive: 0,
      stickyHeight: 0, // 吸顶高度

      whatIsTrainShow: false, // 什么是训练量
      weightShow: false, // 输入体重的弹窗
      userWeight: '', // 用户体重

      muscleShow: false,
      muscleShowObj: {},

      fromOrigin: '', // 来源

      image: '',

      barWidth: 0,
      
    }
  },
  computed: {
    bodyStatus() {
      return function (v) {
        if (v <= 3) {
          return '很低'
        }
        if (v <= 6) {
          return '较低'
        }
        if (v <= 9) {
          return '较高'
        }
        return '非常高'
      }
    },
    trainLevel() {
      return function (v) {
        if (v <= 3) {
          return '较差'
        }
        if (v <= 6) {
          return '一般'
        }
        if (v <= 9) {
          return '较好'
        }
        return '非常好'
      }
    }
  },
  async mounted() {
    // 获取链接参数
    let linkParams = getParam()
    this.recordId = linkParams.id
    // 初始化
    await this.$getAllInfo();
    initBack()

    this.shareParams.path = `/pages/webView/index?webUrl=${window.location.origin}/#/training-records/detail&id=${this.recordId}`

    this.fromOrigin = linkParams.from

    this.$toast.loading({
      duration: 0,
      overlay: true,
      forbidClick: true,
      loadingType: 'spinner',
    })

    this.readRecord()
    await this.getData()

    this.drawPoster()
  },

  methods: {
    appType,
    newAppBack,
    readRecord() {
      this.$axios
        .post(this.baseURLApp + '/store/per/course/relation/readReport', {
          userCoursePlanCourseId: this.recordId
        })
    },
    getData() {
      return this.$axios
        .post(this.baseURLApp + '/store/per/course/relation/poster/v2', {
          coursePlanCourseId: this.recordId
        })
        .then((res) => {
          this.recordData = res.data

          this.$toast.clear()
          console.log('11111');
        }).catch(() => {
          console.log('11111');
          this.$toast.clear()
        })
    },
    drawPoster() {
      console.log('22222');
      this.barWidth = this.$refs.bar.clientWidth

      const canvas = this.$refs.abc
      const opts = {
        backgroundColor: 'transparent', // transparent
        useCORS: true, // 允许跨域
        height: canvas.offsetHeight,
        width: canvas.offsetWidth,
        windowWidth: document.body.scrollWidth,
        windowHeight: document.body.scrollHeight,
      };
      html2canvas(canvas, opts).then((canvas) => {
        let dataURL = canvas.toDataURL();
        this.image = dataURL;

        // 小程序分享 参数传递
        if (appType() === 'mini') {
          wx.miniProgram.postMessage({
            data: {
              type: 'share',
              shareImage: dataURL,
              shareTitle: this.shareParams.title,
              shareUrl: this.shareParams.path,
            }
          });
        }

        // 判断是否ipad教练端进入
        if (this.fromOrigin === 'ipad' && appType() === 'ios') {
          window.webkit.messageHandlers.ipadWechatShare.postMessage({
            type: '3',
            params: {
              title: '快来看看我的训练成果！',
              image: dataURL,
              path: this.shareParams.path,
              miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
              url: 'url',
              multiple: '0.95',
              userName: 'gh_7f5b57b6a76e',
            }
          })
        }
      });
      this.canvasWidth = canvas.offsetWidth

      this.stickyHeight = this.$refs.head ? this.$refs.head.$el.offsetHeight : 0
    },
    submitWeight() {
      if (!Regular.isNumber(this.userWeight)) {
        this.$toast('格式不正确')
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })

      this.$axios.post(this.baseURLApp + '/store/course/calculate/weight', {
        coursePlanCourseId: this.recordId,
        weight: this.userWeight
      }).then((res) => {
        this.weightShow = false
        this.getData()
        this.$toast.clear()
      })

    },
    lookMuscle(item) {
      this.muscleShowObj = {
        name: item.motionName,
        list: item.muscle
      }
      this.muscleShow = true
    },
    // 分享按钮 不同端不同操作
    handleShare() {
      if (appType() === 'mini') {
        this.shareShow = true
      }
      if (appType() === 'ios') {
        window.webkit.messageHandlers.ptReportShare.postMessage({ id: this.recordId })
      }
      if (appType() === 'and') {
        App.ptReportShare(this.recordId)
      }
    },
    imagePreview(img) {
      ImagePreview({
        images: [img],
        showIndex: false
      })
    },
    
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.container {
  padding: 0 0;

  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  .course-box {
    background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/bg2.png);
    background-size: cover;
    padding: calc(132px + env(safe-area-inset-top)) 32px 80px;
    color: #fff;
    margin: -1px 0 0;

    .desc {
      color: #dddddd;
      margin: 24px 0 54px;
    }

    .info {
      .p1 {
        margin: 0 52px 0 0;
      }

      .van-icon {
        margin: 0 12px 0 0;
        font-size: 28px;
      }
    }
  }

  .content-box {
    background-color: #fff;
    border-radius: 32px 32px 0 0;
    margin: -32px 0 0;
    padding: 56px 0 env(safe-area-inset-bottom);

    .van-tab__pane {
      width: 100vw;
    }

    .teacher {
      margin: 0 0 40px;
      padding: 0 32px;
      position: relative;

      .avator {
        width: 104px;
        height: 104px;
        border-radius: 50%;
        margin: 0 24px 0 0;
      }

      .tip {
        width: 276px;
        position: absolute;
        right: 0px;
        top: -114px;
      }

      .p2 {
        color: #6c727a;
        margin: 6px 0 0;
      }
    }

    .radius-box {
      width: 686px;
      padding: 36px 24px;
      margin: 24px 0 0;
      background: #ffffff;
      border-radius: 20px;
      position: relative;

      .p1 {
        color: #6c727a;
        margin: 0 0 22px;
      }

      .p2 {
        color: #3c454e;
        margin: 14px 0 0;
      }

      .tag {
        margin: 0 0 0 12px;
      }

      .p3 {
        color: #267dff;
        text-decoration: underline;
        position: absolute;
        top: 34px;
        right: 28px;
      }

      .no-weight {
        margin: 36px 0 0;

        p {
          color: #ed5c42;
        }

        .tag {
          padding: 10px 12px;
          margin: 0 0 0 12px;
        }
      }
    }

    .scroll-title {
      width: 100%;
      color: #3c454e;
      line-height: 120px;
      padding: 0 32px;
    }

    .train-box {
      background-color: #fff;
      padding: 34px 32px 0;

      .t-title {
        border-left: 6px solid #3c454e;
        padding-left: 12px;
        line-height: 1.1;
      }

      .t-item {
        padding: 32px 0;

        &:not(:last-child) {
          border-bottom: 2px solid #f5f5f5;
        }

        .t-photo {
          width: 176px;
          height: 112px;
          border-radius: 4px;
          background-color: #ccc;
          background-size: cover;
          background-position: center;
        }

        .t-info {
          margin: 0 0 0 24px;

          .name {
            .tag {
              margin: 0 0 0 12px;
            }
          }

          .tags {
            margin: 4px 0 0;

            .tag {
              margin: 8px 12px 0 0;
            }
          }
        }
      }

      // 进度条
      .progress-1 {
        margin: 0 0 32px;

        .bar {
          margin: 0 50px 0 12px;
          height: 40px;
          position: relative;

          .target {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #3d454e;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            color: yellow;
          }

          .bar-grey {
            background-color: #eeeeee;
            border-radius: 12px;
            overflow: hidden;
            height: 24px;
            margin: 8px 0 0;
            position: relative;

            .bar-real {
              height: 100%;
              border-radius: 12px;
              background-color: #3d454e;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }

      .summary {
        margin: 50px 0 0;

        .p2 {
          margin: 12px 0 0;
        }
      }

      .work-item {
        width: 686px;
        padding: 32px;
        background: #f5f9ff;
        border-radius: 12px;
      }
    }
  }

  .share-box {
    width: 100%;
    position: fixed;
    color: #fff;
    z-index: -2;
    top: 400px;

    .info {
      position: absolute;
      bottom: 64px;
      left: 44px;
    }

    img {
      width: 100%;
      display: block;
    }

    span {
      font-size: 80px;
    }
  }

  .base64 {
    width: 100%;
    position: fixed;
    z-index: -1;
    top: 400px;
  }
}

.tag {
  padding: 4px 12px;
  border-radius: 4px;

  &.gradient {
    color: #fff;
    background: linear-gradient(132deg, #d30fbc 0%, #1e84ff 100%);
  }

  &.grey {
    color: #6c727a;
    background: #eeeeee;
  }

  &.black {
    background: #3c454e;
    color: #fff;
  }

  &.blue {
    border: 2px solid #267dff;
    color: #267dff;
  }

  &.purple {
    background: rgba(122, 71, 250, 0.2);
    color: #7a47fa;
  }
}

.common-dialog {
  width: 630px;
  border-radius: 12px;
  padding: 40px 60px;
  box-sizing: border-box;

  .content {
    .title-img {
      width: 100%;
      margin: 0 0 58px;
    }
    .sub-title {
      width: 100%;
      text-align: left;
      margin: 32px 0 0;
    }

    .desc {
      margin: 32px 0 0;

      color: #6c727a;
    }

    .input-box {
      width: 100%;
      margin: 34px 0 0;

      input {
        height: 88px;
        background: #f1f4f9;
        border-radius: 12px;
        padding: 0 24px;
        border: none;
        text-align: center;

        &::placeholder {
          color: #9aa1a9;
        }
      }

      span {
        margin: 0 0 0 8px;
      }
    }

    .button {
      width: 510px;
      height: 96px;
      background: #3c454e;
      border-radius: 8px;
      color: #fff;
      margin: 48px 0 0;
    }

    .tags {
      width: 100%;
      margin: 20px 0 0;

      .tag {
        margin: 8px 12px 0 0;
      }
    }
  }
}
</style>
