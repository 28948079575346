<template>
  <div>
    <nav-bar headerTitle="训练统计" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container col-start-center">
      <div class="nav">
        <ul class="row-center-center">
          <li @click="tabClick(index)" :class="['tab', 'f24', 'row-center-center', tabActive === index ? 'active fw6' : '']" v-for="item, index in tabs">{{ item.name }}</li>
        </ul>
      </div>

      <div class="date-bar row-between-center">
        <van-icon @click="preDate" name="arrow-left" />
        <p class="f32 fw6">{{ displayWord }}</p>
        <van-icon :style="{ opacity: curRange >= 0 ? '0.2' : '1' }" @click="nextDate" name="arrow" />
      </div>
      
      <div class="muscle-outer">
        <muscle :list="trainObj.userMuscleList" :sex="trainObj.sex"></muscle>
        <swiper class="bg-swiper" :options="swipeOptionBg" ref="bgSwiper">
          <swiper-slide v-for="(item, index) in 2" :key="index"></swiper-slide>
        </swiper>
      </div>

      <div class="total-box row-between-center">
        <div class="total-item">
          <p class="p1 f22">本{{ tabs[tabActive].name }}总训练量</p>
          <p class="p2 BebasNeueBold f56">{{ trainObj.trainingVolume }}</p>
        </div>
        <div class="total-item">
          <p class="p1 f22">本{{ tabs[tabActive].name }}总消耗</p>
          <p class="p2 BebasNeueBold f56">
            {{ trainObj.kcal }}
            <span class="f24 fw5">kcal</span>
          </p>
        </div>
      </div>

      <div class="progress-box" v-show="trainObj.userMuscleList">
        <div class="row-between-center">
          <p class="f32 fw6">各肌群锻炼情况</p>
          <p class="f22" style="color: #6C727A;">单位：组</p>
        </div>
        <div class="list flex">
          <div class="left">
            <p class="p1 f24 row-end-center" v-for="item in trainObj.userMuscleList">{{ item.name }}</p>
          </div>
          <div ref="right" class="right flex-auto">
            <div class="bar row-start-center" v-for="item in trainObj.userMuscleList">
              <div class="bar-grey f22 fw6">
                <span>0</span>
                <div :style="`width: ${maxNum === 0 ? maxNum : (barMaxWidth / maxNum) * item.num}px; min-width: ${item.num === 0 ? '0' : '15'}px`" class="bar-real row-end-center">
                  <span>{{ item.num }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import muscle from './components/muscle'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam, getFormatDate } from '@/lib/utils'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    muscle,
    swiper,
    swiperSlide
  },
  data() {
    return {
      tabs: [{ name: '周', value: '1' }, { name: '月', value: '2' }, { name: '年', value: '3' }],
      tabActive: 0,
      searchParams: {
        startTime: '',
        endTime: '',
        userId: ''
      },

      curRange: 0, // 当前周期   -1表示上一个周月年  1表示下一个周月年
      displayWord: '', // 页面展示的日期

      trainObj: {},
      barMaxWidth: 0, // 进度条的最大宽度
      maxNum: 0, // 肌群锻炼组数最大值

      swipeOptionBg: {
        loop: true,
        on:{
          slideNextTransitionEnd: () => {
            this.nextDate()
          },
          slidePrevTransitionEnd: (swiper) => {
            this.preDate()
          },
        }
      },
    }
  },
  computed: {
    bgSwiper() {
      return this.$refs.bgSwiper.swiper
    },
  },
  async created() {
    // 初始化
    await this.$getAllInfo();
    this.searchParams.userId = this.userId
    // this.searchParams.userId = '19552567'

    initBack()

    this.getData()
  },

  methods: {
    newAppBack,
    tabClick(i) {
      this.tabActive = i
      this.curRange = 0

      this.getData()
    },
    getData() {
      this.getDay()
      this.$axios.post(this.baseURLApp + '/store/userTrain/userMuscle', this.searchParams).then((res) => {
        this.trainObj = res.data

        this.$nextTick(() => {
          this.barMaxWidth = this.$refs.right.clientWidth
        })
        this.maxNum = this.trainObj.userMuscleList.reduce((item1, item2) => {
          return item1.num > item2.num ? item1 : item2
        }).num
      })
    },

    preDate() {
      this.curRange--
      this.getData()
    },
    nextDate() {
      console.log('hahahah');
      if (this.curRange >= 0) return
      this.curRange++
      this.getData()
    },
    getDay() {
      const now = new Date();
      const nowDayOfWeek = now.getDay(); //今天本周的第几天
      const year = now.getFullYear()
      const month = now.getMonth()
      const day = now.getDate()

      let startDate = ''
      let endDate = ''
      let display = ''

      // 而外+1 是因为统计的日期是按当前日期的00：00计算  (例如：统计3月的数据  需要传2022-03-01， 2022-04-01)
      switch (this.tabActive) {
        case 1: // 月
          startDate = getFormatDate(new Date(year, month + this.curRange, 1))
          endDate = getFormatDate(new Date(year, month + this.curRange + 1, 1))
          let arr = startDate.split('-')
          display = `${arr[0]}年${arr[1]}月`
          break;
        case 2: // 年
          startDate = getFormatDate(new Date(year + this.curRange, 0, 1))
          endDate = getFormatDate(new Date(year + this.curRange + 1, 0, 1))
          let a = startDate.split('-')
          display = `${a[0]}年`
          break;
        default: // 周
          startDate = getFormatDate(new Date(year, month, (day - nowDayOfWeek + 1) + 7 * this.curRange))
          endDate = getFormatDate(new Date(year, month, day + (7 - nowDayOfWeek) + 1 + 7 * this.curRange))
          display = `${startDate.split('-').join('.')} - ${endDate.split('-').join('.')}`
          break;
      }
      this.searchParams.startTime = startDate
      this.searchParams.endTime = endDate
      this.displayWord = display
    },
  }
}
</script>
<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }
  width: 100%;
  background: #f9fafb;
  padding: calc(138px + env(safe-area-inset-top)) 0 0;
  .nav {
    width: 686px;
    height: 72px;
    background: #eeeeee;
    border-radius: 36px;
    padding: 4px;
    .tab {
      width: 224px;
      height: 64px;
      border-radius: 32px;
      color: #3c454e;
      &.active {
        background: #ffffff;
      }
    }
  }
  .date-bar {
    width: 100%;
    height: 48px;
    margin: 40px 0 0;
    padding: 0 72px;

    .van-icon {
      font-size: 44px;
    }
  }
  .muscle-outer {
    width: 100%;
    position: relative;
    .bg-swiper {
      position: absolute;
      top: 10px;
      width: 100%;
      height: 80%;
      // background-color: cyan;
    }
  }
  .total-box {
    width: 100%;
    padding: 0 32px;
    margin: 32px 0;
    .total-item {
      width: 332px;
      height: 190px;
      background: #ffffff;
      border-radius: 16px;
      padding: 32px 28px;
      .p1 {
        color: #6c727a;
        margin: 0 0 38px;
      }
      .p2 {
        color: #3c454e;
        // display:table-cell;
        // vertical-align:bottom;
      }
    }
  }

  .progress-box {
    width: 100%;
    background-color: #fff;
    padding: 36px 32px calc(env(safe-area-inset-bottom) + 56px);

    color: #3c454e;
    .list {
      width: 100%;
      margin: 56px 0 0;
    }
    .left {
      .p1 {
        height: 70px;
        line-height: 1;
        text-align: right;
        padding: 0 20px 0 0;
      }
    }
    .right {
      position: relative;
      &::before {
        content: "";
        width: 2px;
        height: 100%;
        background-color: rgba(221, 221, 221, 1);
        position: absolute;
        left: -2px;
        top: 0;
      }
      .bar {
        height: 70px;
        .bar-grey {
          width: 100%;
          background: #f9fafb;
          border-radius: 0 12px 12px 0;
          overflow: hidden;
          height: 24px;
          position: relative;
          color: #3c454e;
          span {
            margin: 0 0 0 12px;
          }
          .bar-real {
            width: 0;
            height: 100%;
            border-radius: 0 12px 12px 0;
            background: #3c454e;
            position: absolute;
            top: 0;
            left: 0;
            text-align: right;
            color: #fff;
            span {
              margin: 0 12px 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
