<template>
  <div class="body-box">
    <div class="body row-around-center">
      <!-- 男 正面 -->
      <div class="left" v-if="sex === 1">
        <img :src="`${aliUrl}a1.png`" alt class="tou" />
        <img :src="`${aliUrl}a7.png`" alt class="shenti" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964875')}2.png`" alt class="bozi" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964867')}5.png`" alt class="shoubi" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964865')}4.png`" alt class="xiongbu" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964864')}3.png`" alt class="jianbu" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964868')}6.png`" alt class="fubu" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964870')}8.png`" alt class="shou" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964869')}9.png`" alt class="tun" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964871')}10.png`" alt class="datui" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964872')}11.png`" alt class="xiaotui" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964873')}12.png`" alt class="jiao" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964874')}13.png`" alt class="xin" />
      </div>
      <!-- 女 正面 -->
      <div class="left" v-else>
        
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964870')}8.png`" alt class="shou girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964871')}10.png`" alt class="datui girl" />
        <img :src="`${aliUrl}w7.png`" alt class="shenti girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964867')}5.png`" alt class="shoubi girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964875')}2.png`" alt class="bozi girl" />
        <img :src="`${aliUrl}w1.png`" alt class="tou girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964865')}4.png`" alt class="xiongbu girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964868')}6.png`" alt class="fubu girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964872')}11.png`" alt class="xiaotui girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964873')}12.png`" alt class="jiao girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964874')}13.png`" alt class="xin girl" />
      </div>
      <!-- 男 背面 -->
      <div class="right" v-if="sex === 1">
        <img :src="`${aliUrl}a1b.png`" alt class="tou" />

        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964867')}5b.png`" alt class="shoubi" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964866')}13b.png`" alt class="bei" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964875')}2b.png`" alt class="bozi" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964864')}3b.png`" alt class="jianbu" />
        <!-- <img :src="`${aliUrl}${musclePhotoLevel('945673109308964868')}6b.png`" alt class="fubu" /> -->
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964870')}8b.png`" alt class="shou" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964871')}10b.png`" alt class="datui" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964869')}9b.png`" alt class="tun" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964873')}12b.png`" alt class="jiao" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964872')}11b.png`" alt class="xiaotui" />
      </div>
      <!-- 女 背面 -->
      <div class="right" v-else>
        <img :src="`${aliUrl}w1.png`" alt class="tou girl" />

        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964867')}5b.png`" alt class="shoubi girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964866')}13b.png`" alt class="bei girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964875')}2b.png`" alt class="bozi girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964864')}3b.png`" alt class="jianbu girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964870')}8b.png`" alt class="shou girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964872')}11b.png`" alt class="xiaotui girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964873')}12b.png`" alt class="jiao girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964871')}10b.png`" alt class="datui girl" />
        <img :src="`${aliUrl}${musclePhotoLevel('945673109308964869')}9b.png`" alt class="tun girl" />
      </div>
    </div>

    <div class="body-tip row-center-center" @click="whatIsMuscleShow = true">
      <span class="p1 f20 fw6">高频</span>
      <div class="grid-box row-start-center">
        <span class="grid a"></span>
        <span class="grid b"></span>
        <span class="grid c"></span>
        <span class="grid d"></span>
      </div>
      <span class="p1 f20 fw6">低频</span>
      <van-icon name="question-o" color="#CCCCCC" />
    </div>
    <van-popup class="common-dialog" v-model="whatIsMuscleShow">
      <div class="content col-start-center">
        <img class="title-img" :src="`${aliUrl}body-icon.png`" alt />
        <p class="title f36 fw6">肌群说明</p>
        <p class="desc f28">肌群颜色的深浅，代表的是该肌群下肌肉在周期内锻炼的组数在所有肌群中的排名，越靠前越深。</p>
        <div class="button row-center-center f32" @click="whatIsMuscleShow = false">我知道了</div>
      </div>
    </van-popup>
  </div>
</template>

<script>

export default {
  props: ['list', 'sex'],
  data() {
    return {
      aliUrl: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/',
      whatIsMuscleShow: false
    }
  },
  computed: {
    musclePhotoLevel() {
      return function (id) { //：num组数  numSort排名
        if (this.list && this.list.length === 0) return this.sex === 1 ? 'a' : 'w'
        let curMuscle = this.list.filter((item) => {
          return item.id === id
        })[0]
        if (curMuscle.num === 0) return this.sex === 1 ? 'a' : 'w'
        if (curMuscle.numSort <= 4) return this.sex === 1 ? 'aaaa' : 'wwww'
        if (curMuscle.numSort <= 8) return this.sex === 1 ? 'aaa' : 'www'
        if (curMuscle.numSort <= 12) return this.sex === 1 ? 'aa' : 'ww'
      }
      // 该肌群在周期内锻炼动作组数为0。 a   w
      // 所有肌群按从多到少进行排序，该肌群处于第9 - 12位。aa   ww
      // 所有肌群按从多到少进行排序，该肌群处于第5 - 8位。aaa  www
      // 所有肌群按从多到少进行排序，该肌群处于第1-4位。aaaa   wwww
      // 背面图片名称后面加个b
    }
  },
  methods: {

  },

}
</script>
<style lang="less" scoped>
.body-box {
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 40px;
  margin: 32px 0 0;
  .body {
    img {
      position: absolute;
    }
    .left {
      position: relative;
      width: 260px;
      height: 412px;
      &.girl {
        width: 198px;
        height: 414px;
      }
      .tou {
        width: 46px;
        top: 0;
        left: 106px;
        &.girl {
          width: 44px;
          top: 0;
          left: 78px;
        }
      }
      .shenti {
        width: 90px;
        left: 84px;
        top: 52px;
        &.girl {
          width: 76px;
          left: 62px;
          top: 60px;
        }
      }
      .bozi {
        width: 36px;
        left: 112px;
        top: 40px;
        &.girl {
          width: 26px;
          left: 86px;
          top: 48px;
        }
      }
      .jianbu {
        width: 138px;
        left: 60px;
        top: 72px;
      }
      .xiongbu {
        width: 96px;
        left: 80px;
        top: 74px;
        &.girl {
          width: 76px;
          left: 60px;
          top: 76px;
        }
      }
      .shoubi {
        width: 210px;
        left: 24px;
        top: 103px;
        &.girl {
          width: 158px;
          left: 20px;
          top: 70px;
        }
      }
      .fubu {
        width: 50px;
        left: 104px;
        top: 106px;
        &.girl {
          width: 36px;
          left: 82px;
          top: 114px;
        }
      }

      .shou {
        width: 264px;
        left: -1px;
        top: 186px;
        &.girl {
          width: 200px;
          left: 2px;
          top: 196px;
        }
      }
      .tun {
        width: 80px;
        left: 90px;
        top: 164px;
      }
      .datui {
        width: 96px;
        left: 80px;
        top: 178px;
        &.girl {
          &.girl {
            width: 98px;
            left: 50px;
            top: 166px;
          }
        }
      }
      .xiaotui {
        width: 86px;
        left: 86px;
        top: 266px;
        &.girl {
          width: 80px;
          left: 60px;
          top: 268px;
        }
      }
      .jiao {
        width: 90px;
        left: 84px;
        top: 372px;
        &.girl {
          width: 88px;
          left: 56px;
          top: 376px;
        }
      }
      .xin {
        width: 20px;
        left: 142px;
        top: 90px;
        &.girl {
          width: 16px;
          left: 112px;
          top: 94px;
        }
      }
    }
    .right {
      width: 260px;
      height: 414px;
      position: relative;
      &.girl {
        width: 198px;
        height: 414px;
      }
      .tou {
        width: 46px;
        top: 0;
        left: 107px;
        &.girl {
          width: 42px;
          left: 78px;
          top: 1px;
        }
      }
      .bozi {
        width: 28px;
        left: 116px;
        top: 34px;
        &.girl {
          width: 22px;
          left: 88px;
          top: 40px;
        }
      }
      .shoubi {
        width: 218px;
        left: 20px;
        top: 98px;
        &.girl {
          width: 168px;
          left: 14px;
          top: 88px;
        }
      }
      .shou {
        width: 264px;
        left: -2px;
        top: 190px;
        &.girl {
          width: 202px;
          left: -2px;
          top: 202px;
        }
      }
      .tun {
        width: 80px;
        left: 90px;
        top: 168px;
        &.girl {
          width: 86px;
          left: 56px;
          top: 152px;
        }
      }
      .datui {
        width: 92px;
        left: 84px;
        top: 190px;
        &.girl {
          width: 96px;
          left: 52px;
          top: 188px;
        }
      }
      .xiaotui {
        width: 92px;
        left: 84px;
        top: 282px;
        &.girl {
          width: 86px;
          left: 56px;
          top: 272px;
        }
      }
      .jianbu {
        width: 136px;
        left: 60px;
        top: 74px;
        &.girl {
          width: 108px;
          left: 46px;
          top: 72px;
        }
      }
      .jiao {
        width: 84px;
        left: 88px;
        top: 380px;
        &.girl {
          width: 82px;
          left: 58px;
          top: 382px;
        }
      }
      .bei {
        width: 92px;
        left: 84px;
        top: 32px;
        &.girl {
          width: 78px;
          left: 60px;
          top: 38px;
        }
      }
    }
  }
  .body-tip {
    margin: 40px 0 0;
    .grid-box {
      margin: 0 8px;
    }
    .grid {
      width: 20px;
      height: 20px;
      &.a {
        background-color: rgba(79, 101, 233, 1);
      }
      &.b {
        background-color: rgba(155, 167, 240, 1);
      }
      &.c {
        background-color: rgba(218, 221, 239, 1);
      }
      &.d {
        background-color: rgba(245, 247, 250, 1);
      }
    }
    .p1 {
      color: #9aa1a9;
    }
    .van-icon {
      margin: 0 0 0 8px;
    }
  }
}
.common-dialog {
  width: 630px;
  border-radius: 12px;
  padding: 40px 60px;
  box-sizing: border-box;
  .content {
    .title-img {
      // height: 442px;
      width: 630px;
      margin: 0 0 58px;
    }
    .desc {
      margin: 32px 0 0;
      color: #6c727a;
    }
    .button {
      width: 510px;
      height: 96px;
      background: #3c454e;
      border-radius: 8px;
      color: #fff;
      margin: 48px 0 0;
    }
  }
}
</style>
    
    