<template>
  <div class="detail-data col-start-center">
    <div :class="['content', openStatus ? 'auto' : '']">
      <div class="row-between-center">
        <p class="f24 fw6" v-if="dataObj.recordJson">
          {{bestText}}
          <span class="f36 BebasNeueBold">{{dataObj.recordJson.mainData}}</span>{{dataObj.recordJson.mainUnit}}&nbsp;&nbsp;&nbsp;
          <span class="f36 BebasNeueBold">{{dataObj.recordJson.viceData}}</span>{{dataObj.recordJson.viceUnit}}
        </p>
        <p v-else></p>
        <p class="p1 row-start-center f22" @click="open">
          本周训练数据
          <van-icon name="arrow-down" :class="['arrow', openStatus ? 'active' : '']" />
        </p>
      </div>
      <div class="data row-between-start flex-wrap">


        <div class="group-item flex-auto" v-for="item in dataObj.motionDetailList">
          <div class="row-between-center">
            <div class="tag f20 fw6 black row-center-center">{{ item.group }}</div>
            <p class="f24">{{ item.measure }}</p>
            <p class="f24">{{ item.embellishValue }}</p>
          </div>

          <div class="group-item--children row-between-center blue" v-for="child in item.childrenList">
            <div class="tag f20 fw6 blue row-center-center">{{ child.group }}</div>
            <p class="f24">{{ child.measure }}</p>
            <p class="f24">{{ child.embellishValue }}</p>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataObj: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      openStatus: false,
    }
  },
  computed: {
    bestText() {
      switch (this.dataObj.recordFlag) {
        case '1':
          return '突破历史记录'
          break;
        case '2':
return '突破历史重量'
          break;
        case '3':
return '突破历史距离'
          break;
        case '4':
return '突破历史时长'
        break;
        case '5':
return '目前最佳记录'
          break;
        default:
          return ''
          break;
      }
    }
  },
  methods: {
    open() {
      this.openStatus = !this.openStatus
    }
  },
}
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.detail-data {
  margin: 24px 0 0;
  background-color:rgba(38, 125, 255, .04);
  .content {
    width: 686px;
    height: 94px;
    padding: 22px 28px;
    overflow: hidden;
    &.auto {
      height: auto;
    }
    .p1 {

      color: #6c727a;
      margin: 14px 0 0;
    }
    .arrow {
      width: 20px;
      margin: 0 0 0 4px;
      transition: all 0.2s;
      transform-origin: center;
      &.active {
        transform: rotateZ(-180deg);
      }
    }
    .data {
      margin: 18px 0 0;
    }
    .group-item {
      max-width: 50%;
      min-width: 40%;
      margin: 16px 0 0;
      &:nth-child(odd) {
        padding: 0 30px 0 0;
      }
      &:nth-child(even) {
        padding: 0 0 0 30px;
      }
      .group-item--children {
        width: 100%;
        margin: 16px 0 0;
        &.blue {
        color: rgba(38, 125, 255, 1);
      }
      }
      
    }
  }
  .tag {
    min-width: 80px;
    padding: 4px 12px;
    border-radius: 4px;
    &.black {
      background: #3c454e;
      color: #fff;
    }
    &.blue {
      border: 2px solid #267dff;
      color: #267dff;
    }
  }
}
</style>
