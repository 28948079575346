<template>
  <div class="record-item" @click="goRecordDetail(record.coursePlanCourseId)">
    <div class="row-start-center">
      <h3 class="title ellipsis f28">{{ record.courseName }}</h3>
      <div v-if="isRecent" class="tag black f20">最近一节</div>
    </div>
    <p class="name f22">{{ record.teacherName }}</p>
    <p class="date f20">{{ record.time }}</p>
  </div>
</template>

<script>

export default {
  props: ['record', 'isRecent'],
  data() {
    return {

    }
  },
  methods: {
    goRecordDetail(id) {
      this.$router.push(`/training-records/detail?id=${id}`)
    },
  },

}
</script>
<style lang="less" scoped>
.record-item {
  width: 686px;
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 2px solid #eeeeee;
  }
  .title {
    max-width: 600px;
    color: #242831;
    margin: 0 12px 0 0;
  }
  .name {
    color: #3c454e;
    margin: 16px 0 16px;
  }
  .date {
    color: #6c727a;
  }
  .tag {
    padding: 4px 12px;
    border-radius: 4px;

    &.black {
      background: #3c454e;
      color: #fff;
    }
  }
}
</style>