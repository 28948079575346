<template>
  <div>
    <!-- 分享 -->
    <div :class="['footer', 'row-center-center', scrollShow ? '' : 'hide']">
      <div class="button f32 fw5 row-center-center" @click="shareShow = true">分享海报给好友赚优惠券</div>
    </div>
    <!--  分享配置  -->
    <common-share :value="shareShow" @close="shareSuccess" @share-success="shareSuccess"
      :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <!-- 需要绘制的dom 写这 -->
        <div class="canvas-dom">
          <img class="bg"
            src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/training-records/tranin-pp.png"
            alt="" />
          <div class="content">
            <div class="user row-start-center">
              <img class="head flex-none" :src="recordTotal.headImg" alt="" />
              <p class="name f20 ellipsis">{{ recordTotal.nickName }}</p>
            </div>

            <div class="train-total row-around-center">
              <div class="total-item col-start-center" v-if="recordTotal.trainingVolume">
                <p class="p1 fw6 f56 BebasNeueBold">{{ recordTotal.trainingVolume }}</p>
                <p class="p2 fw6 f18">本次训练量</p>
                <div v-if="recordTotal.record === 1" class="label f16 fw5 row-center-center">破记录</div>
              </div>
              <div class="total-item col-start-center">
                <p class="p1 fw6 f56 BebasNeueBold">{{ recordTotal.kcal }} <span class="f18 fw4">kcal</span> </p>
                <p class="p2 fw6 f18">本次消耗</p>
              </div>
              <div class="total-item col-start-center">
                <p class="p1 fw6 f56 BebasNeueBold">{{ recordTotal.motionNum }} <span class="f18 fw4">个</span> </p>
                <p class="p2 fw6 f18">训练动作</p>
              </div>
            </div>

            <div class="train-data row-between-center flex-wrap">
              <div class="data-item" v-for="item in recordData" :key="item.motionName">
                <div v-if="item.label" :class="['tag', 'f16', 'fw5', 'row-center-center', item.label.indexOf('破') > -1 ? '' : 'green']">{{ item.label }}</div>
                <p class="p1 f24 fw6">{{ item.motionName.length > 10 ? item.motionName.slice(0, 10) + '...' : item.motionName }}</p>
                <p class="p2 f16">本次训练{{ item.trainNum }}组</p>
                <p class="p3 f20">{{ item.best }}</p>
              </div>
            </div>
            <div class="coach row-between-center">
              <div class="row-start-center">
                <img class="avator" :src="recordTotal.teacherInfoDto.teacherImage" alt="">
                <div>
                  <p class="p1 f24 fw6">{{ recordTotal.teacherInfoDto.teacherName }}</p>
                  <p class="p2 f20">长按识别二维码进行约课</p>
                </div>
              </div>
              <div class="qr">
                <img :src="`data:image/png;base64,${this.qr}`" alt="">
              </div>
            </div>
          </div>
        </div>
      </template>
    </common-share>
  </div>

</template>

<script>
import { throttle } from '@/lib/utils'
import commonShare from '@/components/commonShare'

export default {
  props: ['id'],
  components: {
    commonShare
  },
  data() {
    return {
      shareShow: false,
      shareItem: ['posterfriend', 'postermessage', 'save'],
      scrollObj: {
        b: 0,
        a: 0
      },
      scrollShow: true,
      recordData: {},
      recordTotal: {
        teacherInfoDto: {}
      },
      qr: ''
    }
  },
  async mounted() {
    console.log(this.id);
    await this.getTotal()
    this.getData()
    this.getQr()
    window.addEventListener('scroll', throttle(this.scrollToTop))
  },
  methods: {
    shareSuccess() {
      this.shareShow = false
    },
    scrollToTop(e) {
      this.scrollObj.a = window.scrollY
      this.scrollShow = this.scrollObj.b < this.scrollObj.a ? false : true
      this.scrollObj.b = this.scrollObj.a
    },
    getTotal() {
      return this.$axios
        .post(this.baseURLApp + '/store/userTrain/getTrainTotal', {
          userCoursePlanCourseId: this.id
        })
        .then((res) => {
          this.recordTotal = res.data
        })
    },
    getData() {
      this.$axios
        .post(this.baseURLApp + '/store/userTrain/getTrainInfo', {
          userCoursePlanCourseId: this.id
        })
        .then((res) => {
          this.recordData = res.data
        })
    },
    getQr() {
      this.$axios
        .post(this.baseURLApp + '/per/course/relation/sunCode', {
          teacherId: this.recordTotal.coachId
        })
        .then((res) => {
          this.qr = res.data
        })
    },

  },
}
</script>
<style lang="less" scoped>
.footer {
  width: 750px;
  height: 138px;
  box-sizing: content-box;
  padding: 0 0 env(safe-area-inset-bottom);
  padding: 0 0 constant(safe-area-inset-bottom);
  background: #FFFFFF;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  transition: all 1s;

  &.hide {
    transform: translateY(238px);
  }

  .button {
    width: 686px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px;
  }
}

.canvas-dom {
  width: 654px;
  margin: auto;
  position: relative;
  color: #fff;
  background: #292E32;
  box-sizing: border-box;
  .content {
    width: 654px;
    min-height: 1065px;
    padding: 60px 28px 200px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    .user {
      .head {
        width: 56px;
        border-radius: 50%;
        margin: 0 10px 0 0;
      }
    }
    .train-total {
      margin: 70px 0 0;
      .total-item {
        position: relative;
        .label {
          padding: 0 12px;
          height: 30px;
          background: #ED5C42;
          border-radius: 17px;
          position: absolute;
          right: -60px;
          top: -10px;
        }
      }
    }

    .train-data {
      margin: 50px 0 0;
      .data-item {
        width: 260px;
        padding: 17px 12px 17px 17px;
        background: #3C454E;
        border-radius: 14px;
        margin: 0 0 20px;
        overflow: hidden;
        position: relative;
        .tag {
          padding: 0 10px;
          height: 30px;
          background: #ED5C42;
          border-radius: 0px 14px 0px 14px;
          position: absolute;
          right: 0;
          top: 0;
          &.green {
            background: #31CC43;
          }
        }
        .p2 {
          margin: 4px 0 0;
          color: #DDDDDD;
        }
        .p3 {
          margin: 12px 0 0;
        }
      }
    }
    .coach {
      width: 598px;
      position: absolute;
      bottom: 40px;
      left: 28px;
      .avator {
        width: 78px;
        border-radius: 50%;
        margin: 0 17px 0 0;
      }
      .p2 {
        margin: 16px 0 0
      }
    }
    .qr {
      img {
        width: 98px;
        border-radius: 8px;
      }
    }
  }

  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
</style>